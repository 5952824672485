import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './shared/guards/admin.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { BodegaGuard } from './shared/guards/bodega.guard';
import { PublicGuard } from './shared/guards/public.guard';
import { WorkGuard } from './shared/guards/work.guard';

const routes: Routes = [
  // public
  {
    path: '',
    canActivate:[PublicGuard],
    loadChildren: () =>
      import('./public/auth/auth.module').then((m) => m.AuthModule),
  },

  // private - menu
  {
    path: 'menu',
    canActivate:[AuthGuard],
    loadChildren: () =>
      import('./private/menu/menu.module').then((m) => m.MenuModule),
  },

  // private - admin
  {
    path: 'admin',
    canActivate:[AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./private/admin/admin.module').then((m) => m.AdminModule),
  },

  // private -bodega
  {
    path: 'bodega',
    canActivate:[AuthGuard, BodegaGuard],
    loadChildren: () => 
      import('./private/bodega/bodega.module').then((m) => m.BodegaModule),
  },


  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
