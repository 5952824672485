import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BodegaGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}
  
  canActivate(): boolean {
    const _auth = this.auth.cargarUsuario();
    const permisos = _auth.usuario['permisos'];
   
    
    for (let index = 0; index <= permisos.length; index++) {

      // mientras sea permiso bodega
      if (permisos[index].id_permiso === 2) {
        
        return true;
      } 
    }
    this.router.navigate(['/menu']);
    return false;
  }
  
}
