import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Estados, Usuario } from '../models/usuario.model';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.api;
  usuario: Usuario;
  constructor(private http: HttpClient) {}

  login(usuario: Usuario) {
    return this.http.post(`${this.apiUrl}api/auth`, usuario).pipe(
      map((res: any) => {
        this.guardarUsuario(res, this.estadosUsuario(1));
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      })
    );
  }

  logout(): string {
    this.guardarUsuario(this.usuario, this.estadosUsuario(3));
    return 'Se ha cerrado su sesion correctamente.';
  }

  guardarUsuario(usuario: Usuario, estado: Estados): void {
    this.usuario = usuario;
    // this.usuario.estados = estado;
    localStorage.setItem('usuario', btoa(JSON.stringify(usuario)));
    const token = usuario['token'];

    localStorage.setItem('token', token);
  }

  isAuthenticated(): boolean {
    try {
      if (localStorage.getItem('usuario')) {
        
        this.cargarUsuario();
        return true;
        // this.cargarUsuario();
        // if (
        //   this.usuario.estados.isConnected
        //   // &&
        //   // this.usuario.idrol === 'PROCESSOR'
        // ) {
        //   return true;
        // } else {
        //   return false;
        // }
      }
    } catch (error) {
      return false;
    }
  }
  estadosUsuario(tipo?: number): Estados {
    let estado: Estados = {
      isConnected: false,
      isConnecting: false,
      isDisconnecting: false,
    };
    switch (tipo) {
      case 1:
        estado = {
          isConnected: false,
          isConnecting: true,
          isDisconnecting: false,
        };
        break;
      case 2:
        estado = {
          isConnected: true,
          isConnecting: false,
          isDisconnecting: false,
        };
        break;
      case 3:
        estado = {
          isConnected: true,
          isConnecting: false,
          isDisconnecting: true,
        };
        break;

      default:
        estado = {
          isConnected: false,
          isConnecting: false,
          isDisconnecting: false,
        };
        break;
    }
    return estado;
  }

  cargarUsuario(): Usuario {
    try {
      if (localStorage.getItem('usuario')) {
        
        this.usuario = JSON.parse(atob(localStorage.getItem('usuario')));
      } else {
        this.usuario = new Usuario();
      }
      return this.usuario;
    } catch (error) {
      return (this.usuario = new Usuario());
    }
  }

  // cargarUsuario(): Usuario {
  //   try {
  //     if (localStorage.getItem('usuario')) {
  //       // console.log(JSON.parse(atob(localStorage.getItem('usuario'))));
  //       this.usuario = JSON.parse(atob(localStorage.getItem('usuario')));
  //     } else {
  //       this.usuario = new Usuario();
  //     }
  //     return this.usuario;
  //   } catch (error) {
  //     return (this.usuario = new Usuario());
  //   }
  // }
}
