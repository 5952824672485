import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): boolean {
    const _auth = this.auth.cargarUsuario();
    const permisos = _auth.usuario['permisos'];
   
    for (let index = 0; index <= permisos.length; index++) {
      // mientras sea permiso admin
      if (permisos[index].id_permiso === 1) {
        
        return true;
      }
      
    }
    this.router.navigate(['/menu']);
    return false;
  }
}
