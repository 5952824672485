export class Usuario {
  constructor(
    public id?: number | string,
    public id_empleado?: number | string,
    public usuario?: string,
    public rut?: string,
    public correo?:string,
    public password?: string,
    public rol?: string,
    public creado?: string,
    public estado?: string,
    public activo?: string,
    public createdAt?: string,
    public updatedAt?: string,
  
    //  public estados?: Estados
  ) {
    this.rol = 'ADMIN';
  }
}

export interface Estados {
  isConnecting: boolean;
  isConnected: boolean;
  isDisconnecting: boolean;
}
